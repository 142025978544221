@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800;900&display=swap');

html, body {
  padding: 0;
  margin: 0;
  font-family: Kanit, "Kanit", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.card-container {
  @apply p-6 w-full max-w-lg bg-base-100 shadow-md;
}

.button-cw {
  @apply btn rounded-lg border-none bg-accent bg-gradient-to-r from-primary hover:bg-primary;
}

.button {
  @apply btn rounded-lg border-none w-full bg-accent bg-gradient-to-r from-primary hover:bg-primary;
}

.button-white {
  @apply text-primary border-none btn mt-4 rounded-lg w-full bg-white hover:bg-primary hover:text-white;
}

.button-white-outline {
  @apply text-primary border-primary btn mt-4 rounded-lg w-full bg-white hover:bg-primary hover:text-white;
}

.main-text {
  @apply text-7xl font-bold;
}

.sub-text {
  @apply my-4 text-lg font-semibold;
}

.side-menu {
  @apply fixed z-10 w-60 flex-none h-screen bg-base-200 border-r-2 z-20 left-0 top-0;
}

.header-bar {
  @apply fixed h-16 shadow-md flex items-center right-0 top-0 z-20 bg-white;
  width: Calc(100% - 15rem);
}

.page-container {
  @apply ml-60 pt-16 min-h-screen overflow-hidden w-full;
}

.content-container {
  @apply px-6 py-4;
}

.flex-content {
  @apply flex;
}

.card-shadow {
  @apply card shadow-lg m-4 p-4;
}

.ReactModal__Content.ReactModal__Content--after-open {
  position: relative;
  top: 50%;
  margin: auto;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  outline: none;
}

.ReactModal__Overlay {
  background-color: rgba(40, 40, 40, 0.75) !important;
  z-index: 1000 !important;
}

.close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  outline: 0;
  background-color: transparent;
  border: none;
  font-family: FontAwesome !important;
  font-size: 24px;
  cursor: pointer;
}

.close-btn {
  @apply flex bg-white items-center justify-center h-7 w-7 rounded-full;
  position: absolute;
  top: -3.5rem;
  right: -1rem;
}

.close-btn__line {
  position: relative;
  display: flex;
  height: 50%;
  width: 50%;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg);
}

.close-btn__line1 {
  position: absolute;
  width: 100%;
  border: 1px solid rgb(48, 52, 59);
}

.close-btn__line2 {
  position: absolute;
  width: 100%;
  border: 1px solid rgb(48, 52, 59);
  transform: rotate(90deg);
}

.fill-white {
  filter: brightness(10);
}

.blur {
  filter: blur(40px);
}

.disable-btn {
  @apply btn rounded-lg border-none w-full bg-gray-200 cursor-not-allowed;
}

.send-report-btn {
  @apply btn rounded-lg w-full text-yellow-400 border-yellow-400 bg-white cursor-pointer;
}